import React, { Suspense } from 'react';
import { useSWRConfig } from 'swr';
import {
  useNavigate,
  useRouteLoaderData,
  useSearchParams,
  useFetcher,
  Await,
} from '@remix-run/react';
import { Check, MoveLeft, UserCog2 } from 'lucide-react';
import type { InboxItemQuery } from '~/generated/club.server';
import type { loader as CLoader } from '~/routes/_c';
import type { InboxItemEvent } from '~/routes/_c.inbox/use-inbox-item-events';
import { formatUser } from '~/utils/user';
import { Avatar } from './Avatar';
import { Button } from './Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from './Dropdown';
import { Loader } from './Loader';

export function InboxBanner({
  inboxItem,
}: {
  inboxItem: NonNullable<InboxItemQuery['inboxItem']>;
}) {
  const navigate = useNavigate();
  const data = useRouteLoaderData<typeof CLoader>('routes/_c');
  const [searchParams] = useSearchParams();
  const inboxItemId = String(searchParams.get('inboxItemId'));
  const fetcher = useFetcher();
  const [markedAsRead, setMarkedAsRead] = React.useState(false);

  const { mutate, cache } = useSWRConfig();

  const navigateBack = () => navigate(-1);

  React.useLayoutEffect(() => {
    if (inboxItemId && !markedAsRead) {
      mutate('$sub$inbox', () => cache.get('$sub$inbox')?.data, {
        optimisticData: (prev) => {
          const inboxItemEvents = Object.assign([], prev) as InboxItemEvent[];

          for (const inboxItemEvent of inboxItemEvents) {
            if (inboxItemEvent.inboxItem.id === inboxItemId) {
              inboxItemEvent.inboxItem.lastReadAtByViewer = new Date().toISOString();
              break;
            }
          }

          return inboxItemEvents;
        },
      });
      fetcher.submit({ inboxItemId }, { method: 'POST', action: '/inbox?/read' });
      setMarkedAsRead(true);
    }
  }, [inboxItemId, fetcher, markedAsRead, mutate, cache]);

  if (!inboxItemId) return null;

  const isSubmitting = fetcher.state === 'submitting';

  return (
    <div className="sticky top-0 z-50 bg-foreground text-background">
      <div className="px-4 mx-auto flex items-center justify-between bg-foreground py-4 text-background">
        <Button onClick={navigateBack} variant="secondary" size="sm">
          <MoveLeft className="mr-2 size-4" />
          Back to inbox
        </Button>
        <div className="flex items-center gap-4">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              fetcher.submit(
                { inboxItemId, status: 'COMPLETED' },
                { method: 'POST', action: '/inbox?/changeStatus' },
              );
              navigateBack();
            }}
            loading={isSubmitting && fetcher.formAction.endsWith('?/changeStatus')}
            disabled={inboxItem?.status === 'COMPLETED'}
          >
            <Check className="mr-2 size-4" /> Mark as completed
          </Button>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="secondary"
                size="sm"
                loading={isSubmitting && fetcher.formAction.endsWith('?/assign')}
              >
                <UserCog2 className="mr-2 size-4" />
                Assign
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuRadioGroup
                value={inboxItem?.assignee?.id || ''}
                onValueChange={(userId) => {
                  fetcher.submit(
                    { inboxItemId, userId },
                    { method: 'POST', action: '/inbox?/assign' },
                  );
                  navigateBack();
                }}
              >
                <Suspense fallback={<Loader />}>
                  <Await errorElement={<span>Error</span>} resolve={data?.admins}>
                    {(admins) =>
                      admins?.map((admin) => (
                        <DropdownMenuRadioItem key={admin.id} value={admin.id}>
                          <Avatar user={admin} size="xs" className="mr-2" /> {formatUser(admin)}
                        </DropdownMenuRadioItem>
                      ))
                    }
                  </Await>
                </Suspense>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
}
