import React from 'react';
import type { LinkProps, UIMatch } from '@remix-run/react';
import {
  Link,
  NavLink,
  useFetcher,
  useMatches,
  useNavigate,
  useNavigation,
  useSubmit,
} from '@remix-run/react';
import { BellSkeleton, Notifications } from '~/routes/_c.inbox/Notifications';
import type { loader } from '~/routes/command';
import type { UserSession } from '~/types';
import { cn } from '~/utils/cn';
import { formatUser } from '~/utils/user';
import { Avatar } from './Avatar';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from './Breadcrumb';
import { Button } from './Button';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
  CommandSeparator,
} from './Command';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './Dropdown';
import { EqualIcon, LogOut, SearchIcon, User } from 'lucide-react';
import { Loader } from './Loader';
import { Logo } from './Logo';
import { ScrollArea } from './ScrollArea';
import { Sheet, SheetContent, SheetTrigger } from './Sheet';

function LoadingLogo(props: React.ComponentPropsWithRef<typeof Logo>) {
  const navigation = useNavigation();
  const [isLoadingNewRoute, setLoadingNewRoute] = React.useState(false);

  React.useEffect(() => {
    if (
      (navigation.state === 'loading' && navigation.formAction == null) ||
      (navigation.state === 'loading' && navigation.formMethod === 'GET')
    ) {
      setLoadingNewRoute(true);
    }

    return () => {
      setLoadingNewRoute(false);
    };
  }, [navigation.formAction, navigation.state, navigation.formMethod]);

  return (
    <Logo {...props} className={cn({ 'animate-colors': isLoadingNewRoute }, props.className)} />
  );
}

interface Props {
  user: UserSession & { socketToken: string };
  loading?: boolean;
}

export function Header({ user, loading }: Props) {
  const submit = useSubmit();
  const command = useFetcher<typeof loader>();
  const navigate = useNavigate();
  const matches = useMatches() as UIMatch<
    any,
    | { breadcrumb?: (data?: any) => React.ReactNode }
    | {
        tabs?: (data?: any) => { name: string; href: string; count?: number }[];
      }
  >[];
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const users = command.data?.users || [];
  const accommodations = command.data?.accommodations || [];
  const activities = command.data?.activities || [];
  const threads = command.data?.threads || [];

  const runCommand = React.useCallback((cmd: () => void) => {
    setOpen(false);
    cmd();
  }, []);

  const isEmpty =
    command.data?.users != null &&
    command.data?.accommodations != null &&
    command.data?.activities != null &&
    command.data?.threads != null &&
    command.state === 'idle';

  const tabs = matches.filter(
    (match) => match.handle && 'tabs' in match.handle && match.handle.tabs,
  );

  return (
    <div className="supports-backdrop-blur:bg-background/60 sticky top-0 z-40 w-full border-b bg-background/70 backdrop-blur-sm">
      <header>
        <div className="px-4 mx-auto flex h-14 items-center justify-between gap-x-8">
          <div className="flex items-center gap-4">
            <Link to="/">
              <LoadingLogo />
            </Link>
            <Breadcrumb className="hidden md:block">
              <BreadcrumbList>
                {matches
                  .filter(
                    (match) =>
                      match.handle != null &&
                      'breadcrumb' in match.handle &&
                      !!match.handle.breadcrumb,
                  )
                  .map((match, index, arr) =>
                    'breadcrumb' in match.handle ? (
                      <React.Fragment key={match.id}>
                        <BreadcrumbItem key={match.id} className="max-w-80">
                          <BreadcrumbLink asChild>
                            <NavLink to={match.pathname} className="truncate" prefetch="intent">
                              {match.handle?.breadcrumb?.(match.data)}
                            </NavLink>
                          </BreadcrumbLink>
                        </BreadcrumbItem>
                        {index < arr.length - 1 ? <BreadcrumbSeparator /> : null}
                      </React.Fragment>
                    ) : null,
                  )}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <div className="flex place-content-center items-center gap-4">
            <MainNav />

            <Button
              variant="ghost"
              className="size-8 rounded-full text-muted-foreground"
              size="icon"
              onClick={() => setOpen(true)}
            >
              <SearchIcon className="size-4" />
            </Button>

            {loading ? <BellSkeleton /> : <Notifications user={user} />}

            <DropdownMenu>
              <DropdownMenuTrigger className="grid place-content-center">
                <Avatar
                  size="sm"
                  // @ts-expect-error mismatch
                  user={user}
                  disableRing
                  disableMenu
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => navigate(`/users/${user.id}`)}>
                  <User className="mr-2 size-4" />
                  Profile
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate('/releases')}>
                  <span className="mr-2">🆕</span> Releases
                </DropdownMenuItem>
                <DropdownMenuSeparator />

                <DropdownMenuItem
                  onClick={() => submit(null, { method: 'post', action: '/logout' })}
                >
                  <LogOut className="mr-2 size-4 " />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <MobileNav />
          </div>
        </div>
      </header>

      {tabs?.length ? (
        <div className="pb-2">
          <div className="scrollbar-hide flex items-center overflow-x-auto">
            {tabs.map((match) =>
              'tabs' in match.handle
                ? match.handle?.tabs?.(match.data).map((tab) => (
                    <NavLink
                      to={tab.href}
                      key={tab.href}
                      className={({ isActive }) =>
                        cn(
                          'flex h-7 items-center justify-center whitespace-nowrap rounded-md px-4 text-center text-sm transition-colors first:ml-4 last:mr-4 hover:text-primary',
                          isActive ? 'bg-muted font-medium text-primary' : 'text-muted-foreground',
                        )
                      }
                      prefetch="intent"
                      end
                    >
                      {tab.name}
                      {tab.count != null ? (
                        <span
                          className="ml-1 inline-flex min-w-5 items-center justify-center rounded-full border border-white bg-muted px-1.5 py-0.5 text-xs text-muted-foreground"
                          style={{
                            fontFeatureSettings: 'tnum',
                            fontVariantNumeric: 'tabular-nums',
                            pointerEvents: 'all',
                            transition: 'background-color .1s',
                            cursor: 'pointer',
                          }}
                        >
                          {tab.count}
                        </span>
                      ) : null}
                    </NavLink>
                  ))
                : null,
            )}
          </div>
        </div>
      ) : null}

      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          placeholder="Search by keyword"
          value={search}
          onValueChange={(s) => {
            setSearch(s);
            if (s.length > 2) {
              command.load(`/command?search=${s}`);
            }
          }}
        />
        {command.state === 'loading' ? (
          <CommandLoading>
            <span className="absolute right-0 top-0 z-10 grid h-[47px] w-[49px] place-content-center place-items-center bg-popover">
              <Loader className="size-4 text-muted-foreground" />
            </span>
          </CommandLoading>
        ) : null}
        <CommandList>
          {isEmpty ? <CommandEmpty>No results found.</CommandEmpty> : null}
          {users.length ? (
            <>
              <CommandGroup heading="Users">
                {users.map((user) => (
                  <CommandItem
                    key={user.id}
                    value={user.id}
                    onSelect={(v) => runCommand(() => navigate(`/users/${v}`))}
                  >
                    <Avatar size="sm" user={user} className="mr-4" />
                    {formatUser(user)}
                  </CommandItem>
                ))}
              </CommandGroup>
              {!accommodations?.length && !activities.length && !threads.length ? null : (
                <CommandSeparator alwaysRender />
              )}
            </>
          ) : null}

          {activities.length ? (
            <>
              <CommandGroup heading="Activities">
                {activities.map((activity) => (
                  <CommandItem
                    key={activity.id}
                    value={activity.id}
                    onSelect={(v) => runCommand(() => navigate(`/activities/${v}`))}
                  >
                    {activity.name}
                  </CommandItem>
                ))}
              </CommandGroup>
              {!accommodations?.length && !threads.length ? null : (
                <CommandSeparator alwaysRender />
              )}
            </>
          ) : null}

          {threads.length ? (
            <>
              <CommandGroup heading="Threads">
                {threads.map((thread) => (
                  <CommandItem
                    key={thread.id}
                    value={thread.id}
                    onSelect={(v) => runCommand(() => navigate(`/discussions/threads/${v}`))}
                  >
                    <span className="line-clamp-1">{thread.firstMessage?.body}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
              {!accommodations.length ? null : <CommandSeparator alwaysRender />}
            </>
          ) : null}

          {accommodations.length ? (
            <CommandGroup heading="Accommodations">
              {accommodations.map((accommodation) => (
                <CommandItem
                  key={accommodation.id}
                  value={accommodation.id}
                  onSelect={(v) => runCommand(() => navigate(`/accommodations/${v}`))}
                >
                  {accommodation.headline}
                </CommandItem>
              ))}
            </CommandGroup>
          ) : null}
        </CommandList>
      </CommandDialog>
    </div>
  );
}

const NAV_LINK = [
  { name: 'Users', href: '/users' },
  { name: 'Accommodations', href: '/accommodations' },
  { name: 'Bookings', href: '/bookings' },
  { name: 'Activities', href: '/activities' },
  { name: 'Threads', href: '/discussions/threads' },
  { name: 'Library', href: '/library' },
  { name: 'Subscriptions', href: '/subscriptions' },
];

function MainNav() {
  return (
    <div className="hidden md:flex">
      <nav className="flex items-center space-x-6 text-sm font-medium">
        {NAV_LINK.map((link) => (
          <NavLink
            key={link.href}
            to={link.href}
            className={({ isActive }) =>
              cn(
                'transition-colors hover:text-foreground/80',
                isActive ? 'text-foreground' : 'text-foreground/60',
              )
            }
          >
            {link.name}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

function MobileNav() {
  const [open, setOpen] = React.useState(false);

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="rounded-full px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
          size="icon"
        >
          <EqualIcon className="size-6" />
          <span className="sr-only w-auto">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent className="pr-0">
        <MobileLink to="/" className="flex items-center" onOpenChange={setOpen}>
          <LoadingLogo className="mr-2 size-4" />
          <span className="font-bold">Colette</span>
        </MobileLink>
        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 pl-6">
          <div className="flex flex-col space-y-3">
            <MobileLink to="/" onOpenChange={setOpen}>
              Dashboard
            </MobileLink>

            {NAV_LINK.map(
              (item) =>
                item.href && (
                  <MobileLink key={item.href} to={item.href} onOpenChange={setOpen}>
                    {item.name}
                  </MobileLink>
                ),
            )}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}

interface MobileLinkProps extends LinkProps {
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
  className?: string;
}

function MobileLink({ to, onOpenChange, className, children, ...props }: MobileLinkProps) {
  const navigate = useNavigate();

  return (
    <Link
      prefetch="intent"
      to={to}
      onClick={() => {
        navigate(to.toString());

        onOpenChange?.(false);
      }}
      className={cn(className)}
      {...props}
    >
      {children}
    </Link>
  );
}
